<template>
  <v-app>
    <section class="my-16 analiz" v-if="questions">
      <v-row class="mx-16 py-4" justify="space-between">
        <div>
          <h1>
            {{ asuulga.name }}
          </h1>
          <p v-if="numberOfQuestions">
            Нийт асуултын тоо: {{ numberOfQuestions }}
          </p>
        </div>

        <v-col class="text-right">
          <v-btn
            small
            title="Мэдээлэл татаx"
            @click="_downloadExcel()"
            dark
            class="border-radius-sm text-xxs text-white shadow-none font-weight-bold px-3 py-1 text-uppercase btn-success bg-gradient-success mr-4"
            ><v-icon size="22" class="mr-2">mdi-file-excel</v-icon>татаx</v-btn
          >

          <v-btn
            small
            @click="closeAsuulgaSlide = !closeAsuulgaSlide"
            class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bo‰ld px-3 py-1 text-uppercase btn-info bg-gradient-info"
          >
            Шалгалтын материал
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mx-auto">
        <v-data-table
          :headers="headers"
          :items="questions"
          :items-per-page="-1"
          class="elevation-1 pb-10 pa-10"
          style="overflow-y: auto; height: 800px"
          sort-by="correctPercent"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td
                :style="
                  'A' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'A' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'B' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'B' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'C' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'C' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'D' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'D' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'E' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'E' ? 'Зөв.x' : ''"
              ></td>
              <td>{{ _getReducedNumber(props.item.correctPercent) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="closeAsuulgaSlide">
        <v-card>
          <div v-html="_getSlideLink()"></div>
          <div class="d-flex flex-row justify-end">
            <v-btn
              class="bg-gradient-danger white--text mb-3 mr-3"
              @click="closeAsuulgaSlide = !closeAsuulgaSlide"
              >Хаах</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
//const fb = require("../../../firebaseConfig.js");
import XLSX from "xlsx";

export default {
  data: () => ({
    totalParticipants: 0,
    numberOfQuestions: 0,
    loading: false,
    matrix: null,
    results: null,
    questions: null,
    headers: [
      {
        text: "Асуултын дугаар",
        value: "questionIndex",
        width: "15%",
      },
      { text: "A", value: "A", align: "center" },
      { text: "B", value: "B", align: "center" },
      { text: "C", value: "C", align: "center" },
      { text: "D", value: "D", align: "center" },
      { text: "E", value: "E", align: "center" },
      { text: "Xүндийн зэрэг %", value: "correctPercent", align: "center" },
    ],
    closeAsuulgaSlide: false,
  }),
  props: {
    asuulga: {
      type: Object,
      required: true,
    },
    // numberOfQuestions: {
    //   type: Number,
    //   required: true,
    // },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  async created() {
    if (this.asuulga && this.asuulga.ref) {
      this.loading = true;
      var questions = [];
      var x = await this.asuulga.ref
        .collection("categories")
        .doc("1")
        .collection("questions")
        .get();
      x.forEach((doc) => {
        let question = doc.data();
        question.ref = doc.ref;
        question.id = doc.id;
        questions.push(question);
      });
      this.numberOfQuestions = x.size;

      this.asuulga.ref
        .collection("daalgavar-analyzed")
        .orderBy("questionIndex", "asc")
        .get()
        .then((docs) => {
          this.questions = [];
          var counter = 1;
          docs.forEach((doc) => {
            var exists = questions.find(
              (q) => q.index == doc.data().questionIndex
            );
            if (exists) {
              let question = doc.data();
              question.id = doc.id;
              question.ref = doc.ref;
              question.name = counter++;
              // this._checkCorrectAnswer(question);
              question.correctPercent = (
                (100 *
                  (question[question.correctAnswer]
                    ? question[question.correctAnswer]
                    : 0)) /
                this._getPercentOfCorrectAnswer(question)
              ).toFixed(2);

              this.questions.push(question);
            }
          });
        });

      // this.asuulga.ref
      //   .collection("results")
      //   .get()
      //   .then(async (docs) => {
      //     if (!docs.empty) {
      //       this.matrix = [[]];
      //       this.results = [];
      //       var counter = 1;
      //       this.sumOfAverageTotalScores = 0;

      //       docs.forEach((doc) => {
      //         let result = doc.data();
      //         result.ref = doc.ref;
      //         result.id = doc.id;
      //         result.index = counter;
      //         result.percent =
      //           ((result.totalScore / this.totalScore) * 100).toFixed(0) + "%";

      //         result.rowData = [];
      //         for (var i = 0; i < 5; i++) {
      //           // var qcounter = 0;
      //           for (var question of result.categories[0].questions) {
      //             result.rowData.push({
      //               qid: question.id,
      //               answerid: i,
      //               answered: question.answers[i].answered,
      //             });
      //             // qcounter++;
      //           }
      //         }
      //         this.results.push(result);
      //         if (result.skipped == true) {
      //           console.log("skipped");
      //         } else {
      //           this.sumOfAverageTotalScores =
      //             this.sumOfAverageTotalScores + result.totalScore;
      //           if (this.maxTotalScore < result.totalScore)
      //             this.maxTotalScore = result.totalScore;
      //         }
      //         counter++;
      //         // }
      //       });
      //       this.totalParticipants = docs.size;
      //       this.loading = false;
      //       for (var i = 0; i < 5; i++) {
      //         this.matrix[i] = [];
      //         for (var k = 0; k < 50; k++) {
      //           this.matrix[i][k] = this._getScore(i, k);
      //         }
      //       }
      //     } else {
      //       //this.$router.push({ name: "PageSoril Assignments" });
      //       // alert("Шалгалтыг өгсөн сурагч байхгүй!");
      //     }
      //   });
    }
  },
  methods: {
    _getReducedNumber(correctPercent) {
      return (100 - correctPercent).toFixed(2);
    },
    _downloadExcel() {
      var results = [];
      this.questions.forEach((question) => {
        results.push({
          "Асуултын No": question.questionIndex,
          A: question.A,
          B: question.B,
          C: question.C,
          D: question.D,
          E: question.E,
          "Зөв Хариулт": question.correctAnswer,
          "Зөв Хариулсан": question.correctPercent + "%",
        });
      });

      const data = XLSX.utils.json_to_sheet(results);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, this.asuulga.name + "-Анализ.xlsx");
    },
    _getSlideLink() {
      var xx = null;
      if (this.asuulga.slideUrl) {
        if (this.asuulga.slideUrl.includes("docs.google.com")) {
          xx = this.asuulga.slideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            this.asuulga.slideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },
    _getPercentOfCorrectAnswer(item) {
      var sum = 0;
      if (item.A) sum = sum + item.A;
      if (item.B) sum = sum + item.B;
      if (item.C) sum = sum + item.C;
      if (item.D) sum = sum + item.D;
      if (item.E) sum = sum + item.E;
      return sum;
    },
    _checkCorrectAnswer(quest) {
      if (this.asuulga) {
        for (var question of this.categories[0].questions) {
          if (question.index == quest.questionIndex) {
            for (var answer of question.answers) {
              console.log(answer.correct, question.ref.path, answer.name);
              if (answer.correct) {
                quest.correctAnswer = answer.name;
              } else {
                quest.correctAnswer = null;
              }
            }

            break;
          } //fi
        }
      }
    },
    _getSum(columnIndex) {
      var sum = 0;
      var xx = 0;
      if (this.matrix) {
        for (var mat of this.matrix) {
          sum = sum + mat[columnIndex - 1][0];
          if (mat[columnIndex - 1][1]) xx = mat[columnIndex - 1][0];
        }
      }
      return ((xx / sum) * 100).toFixed(2);
    },
    // _getScore(answerOptionIndex, qindex) {
    //   var counter = 0;
    //   var correct = false;

    //   if (this.results) {
    //     for (var student of this.results) {
    //       var qqq = student.categories[0].questions;
    //       if (qqq.length > 0) {
    //         var aaanswers = qqq[qindex].answers;
    //         if (aaanswers[answerOptionIndex].answered) {
    //           if (aaanswers[answerOptionIndex].correct) correct = true;
    //           counter++;
    //         }
    //       }
    //     }
    //   }
    //   return [counter, correct];
    // },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.analiz table,
.analiz th,
.analiz td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
td {
  width: 30px;
  padding: 1px;
}
</style>
