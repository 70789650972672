<template>
  <v-app>
    <section class="mt-16 analiz" v-if="results">
      <v-card max-width="50%" class="mx-auto pa-10">
        <v-row class="ma-0" justify="space-between">
          <div>
            <h1>
              {{ asuulga.name }}, ({{ asuulga.finalNumberOfParticipants }}
              оролцогчид)
            </h1>
            <p v-if="numberOfQuestions">
              Нийт асуултын тоо: {{ numberOfQuestions }}
            </p>
          </div>
          <v-btn
            @click="_downloadExcel()"
            class="bg-gradient-success white--text"
          >
            Download Excel
          </v-btn>
        </v-row>

        <!-- <table style="width: 100% !important;font-size: 13px; border: 1px solid black ">
          <thead>
            <tr>
              <th>No</th>
              <th v-for="(row, aindex) in matrix[0]" :key="'aasdasd'+aindex" class="">
                <td style=" border: none; font-weight: normal" class="pa-4 pl-11">
                {{ String.fromCharCode(94 + aindex + 3).toUpperCase() }}
              </td>
              </th>
              <td class="px-10">Correct %</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, aindex) in numberOfQuestions" :key="aindex">
              <td style="width: 40px" class="pa-3">
                {{aindex+1}}
              </td>
              <td class="pa-3"
                v-for="(column, cindex) in matrix[aindex]"
                :key="'col'+cindex"
                :style="
                  column[1] ? 'background-color: #98ec2d !important;' : ''
                "
              >
                {{ column[0] }}
             </td>
             <td>{{ _getSum(aindex+1) }}%</td>
            </tr>
          </tbody>
        </table> -->

        <v-data-table
          :headers="headers"
          :items="results"
          :items-per-page="-1"
          class="elevation-1"
          sort-by="correctPercent"
        >
          <template slot="item" slot-scope="props">
            <tr @click="closeAsuulgaSlide = !closeAsuulgaSlide">
              <td>{{ props.item.questionIndex }}</td>
              <td
                :style="
                  'A' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'A' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'B' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'B' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'C' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'C' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'D' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'D' ? 'Зөв.x' : ''"
              ></td>
              <td
                :style="
                  'E' == props.item.correctAnswer
                    ? 'background-color: #98ec2d !important;'
                    : ''
                "
                v-text="props.item.correctAnswer == 'E' ? 'Зөв.x' : ''"
              ></td>
              <td>{{ props.item.correctPercent }}%</td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="closeAsuulgaSlide">
          <v-card>
            <div v-html="_getSlideLink()"></div>
            <div class="d-flex flex-row justify-end">
              <v-btn
                class="bg-gradient-danger white--text mb-3 mr-3"
                @click="closeAsuulgaSlide = !closeAsuulgaSlide"
                >Хаах</v-btn
              >
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
//const fb = require("../../../firebaseConfig.js");
import XLSX from "xlsx";
export default {
  data: () => ({
    totalParticipants: 0,
    numberOfQuestions: 0,
    loading: false,
    matrix: null,
    results: null,
    questions: null,
    closeAsuulgaSlide: false,
    headers: [
      {
        text: "Асуултын дугаар",
        value: "questionIndex",
        width: "15%",
      },
      { text: "A", value: "A", align: "center" },
      { text: "B", value: "B", align: "center" },
      { text: "C", value: "C", align: "center" },
      { text: "D", value: "D", align: "center" },
      { text: "E", value: "E", align: "center" },
      { text: "Correct %", value: "correctPercent", align: "center" },
    ],
  }),
  props: {
    asuulga: {
      type: Object,
      required: true,
    },
    // numberOfQuestions: {
    //   type: Number,
    //   required: true,
    // },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  async created() {
    if (this.asuulga && this.asuulga.ref) {
      this.loading = true;

      // this.asuulga.ref.collection("daalgavar-analyzed")
      //   .orderBy("questionIndex", "asc")
      //   .get()
      //   .then((docs) => {
      //     this.questions = [];
      //     docs.forEach((doc) => {
      //       let question = doc.data();
      //       question.id = doc.id;
      //       question.ref = doc.ref;
      //       // this._checkCorrectAnswer(question);
      //       question.correctPercent = (100* question[question.correctAnswer]/this._getPercentOfCorrectAnswer(question)).toFixed(2)
      //       this.questions.push(question);
      //     });
      //   });
      var x = await this.asuulga.ref
        .collection("categories")
        .doc("1")
        .collection("questions")
        .get();
      this.numberOfQuestions = x.size;

      this.asuulga.ref
        .collection("results")
        .where("schoolRef", "==", this.userData.school.ref)
        .get()
        .then(async (docs) => {
          if (!docs.empty) {
            this.results = [];
            this.sumOfAverageTotalScores = 0;
            this.asuulga.finalNumberOfParticipants = docs.size;
            docs.forEach((doc) => {
              let result = doc.data();

              result.categories[0].questions.forEach((question) => {
                if (!this.results[question.index - 1]) {
                  this.results[question.index - 1] = {
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0,
                    E: 0,
                    totalAnswers: 0,
                    correctAnswered: 0,
                    questionIndex: question.index,
                  };
                }
                if (question.answers.find((answer) => answer.answered)) {
                  question.answers.forEach((answer) => {
                    if (answer.correct) {
                      this.results[question.index - 1].correctAnswer =
                        answer.name;
                    }
                    if (answer.answered && answer.correct) {
                      this.results[question.index - 1][answer.name]++;
                      this.results[question.index - 1].totalAnswers++;
                      this.results[question.index - 1].correctAnswered++;
                    } else if (answer.answered && answer.correct == false) {
                      this.results[question.index - 1][answer.name]++;
                      this.results[question.index - 1].totalAnswers++;
                    }
                  });
                } else {
                  this.results[question.index - 1].totalAnswers++;
                  if (!this.results[question.index - 1].correctAnswer)
                    question.answers.forEach((answer) => {
                      if (answer.correct) {
                        this.results[question.index - 1].correctAnswer =
                          answer.name;
                      }
                    });
                }

                console.log(this.results[question.index - 1]);
                this.results[question.index - 1].correctPercent = (
                  (this.results[question.index - 1].correctAnswered /
                    this.results[question.index - 1].totalAnswers) *
                  100
                ).toFixed(2);
              });
            });
          } else {
            //this.$router.push({ name: "PageSoril Assignments" });
            // alert("Шалгалтыг өгсөн сурагч байхгүй!");
          }
        });
    }
  },
  methods: {
    _downloadExcel() {
      var results = [];
      this.results.forEach((question) => {
        results.push({
          "Асуултын No": question.questionIndex,
          A: question.A,
          B: question.B,
          C: question.C,
          D: question.D,
          E: question.E,
          "Зөв Хариулт": question.correctAnswer,
          "Зөв Хариулсан": question.correctPercent + "%",
        });
      });

      const data = XLSX.utils.json_to_sheet(results);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, this.asuulga.name + "-Анализ.xlsx");
    },
    _getSlideLink() {
      var xx = null;
      if (this.asuulga.slideUrl) {
        if (this.asuulga.slideUrl.includes("docs.google.com")) {
          xx = this.asuulga.slideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:780px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            this.asuulga.slideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },
    _getPercentOfCorrectAnswer(item) {
      var sum = 0;
      if (item.A) sum = sum + item.A;
      if (item.B) sum = sum + item.B;
      if (item.C) sum = sum + item.C;
      if (item.D) sum = sum + item.D;
      if (item.E) sum = sum + item.E;
      return sum;
    },
    _checkCorrectAnswer(quest) {
      if (this.asuulga) {
        for (var question of this.categories[0].questions) {
          if (question.index == quest.questionIndex) {
            for (var answer of question.answers) {
              console.log(answer.correct, question.ref.path, answer.name);
              if (answer.correct) {
                quest.correctAnswer = answer.name;
              } else {
                quest.correctAnswer = null;
              }
            }

            break;
          } //fi
        }
      }
    },
    _getSum(columnIndex) {
      var sum = 0;
      var xx = 0;
      if (this.matrix) {
        for (var q of this.matrix[columnIndex - 1]) {
          sum = sum + q[0];
          if (q[1]) {
            xx = xx + q[0];
          }
        }
      }
      return ((xx / sum) * 100).toFixed(2);
    },
    _getScore(answerOptionIndex, qindex) {
      var counter = 0;
      var correct = false;

      if (this.results) {
        for (var student of this.results) {
          var qqq = student.categories[0].questions;
          var aaanswers = qqq[qindex].answers;
          if (aaanswers[answerOptionIndex].correct) correct = true;

          if (aaanswers[answerOptionIndex].answered) {
            counter++;
          }
        }
      }
      return [counter, correct];
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.analiz table,
.analiz th,
.analiz td {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
td {
  width: 30px;
  padding: 1px;
}
</style>
