var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.questions)?_c('section',{staticClass:"my-16 analiz"},[_c('v-row',{staticClass:"mx-16 py-4",attrs:{"justify":"space-between"}},[_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.asuulga.name)+" ")]),(_vm.numberOfQuestions)?_c('p',[_vm._v(" Нийт асуултын тоо: "+_vm._s(_vm.numberOfQuestions)+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"border-radius-sm text-xxs text-white shadow-none font-weight-bold px-3 py-1 text-uppercase btn-success bg-gradient-success mr-4",attrs:{"small":"","title":"Мэдээлэл татаx","dark":""},on:{"click":function($event){return _vm._downloadExcel()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"22"}},[_vm._v("mdi-file-excel")]),_vm._v("татаx")],1),_c('v-btn',{staticClass:"border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bo‰ld px-3 py-1 text-uppercase btn-info bg-gradient-info",attrs:{"small":""},on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v(" Шалгалтын материал ")])],1)],1),_c('v-card',{staticClass:"mx-auto"},[_c('v-data-table',{staticClass:"elevation-1 pb-10 pa-10",staticStyle:{"overflow-y":"auto","height":"800px"},attrs:{"headers":_vm.headers,"items":_vm.questions,"items-per-page":-1,"sort-by":"correctPercent"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{style:('A' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'A' ? 'Зөв.x' : '')}}),_c('td',{style:('B' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'B' ? 'Зөв.x' : '')}}),_c('td',{style:('C' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'C' ? 'Зөв.x' : '')}}),_c('td',{style:('D' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'D' ? 'Зөв.x' : '')}}),_c('td',{style:('E' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'E' ? 'Зөв.x' : '')}}),_c('td',[_vm._v(_vm._s(_vm._getReducedNumber(props.item.correctPercent)))])])]}}],null,false,3659757566)})],1),_c('v-dialog',{model:{value:(_vm.closeAsuulgaSlide),callback:function ($$v) {_vm.closeAsuulgaSlide=$$v},expression:"closeAsuulgaSlide"}},[_c('v-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm._getSlideLink())}}),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"bg-gradient-danger white--text mb-3 mr-3",on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v("Хаах")])],1)])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }