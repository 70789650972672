import { render, staticRenderFns } from "./AllQuestionsAnalysisAllSchool.vue?vue&type=template&id=5bffd133&"
import script from "./AllQuestionsAnalysisAllSchool.vue?vue&type=script&lang=js&"
export * from "./AllQuestionsAnalysisAllSchool.vue?vue&type=script&lang=js&"
import style0 from "./AllQuestionsAnalysisAllSchool.vue?vue&type=style&index=0&id=5bffd133&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports