<template>
  <section>
    <SingleSchool
      v-if="isSingleSchool"
      :asuulga="asuulga"
    ></SingleSchool>
    <AllSchool
      v-else
      :asuulga="asuulga"
    ></AllSchool>
  </section>
</template>
<script>
import SingleSchool from "./AllQuestionsAnalysisSingleSchool.vue";
import AllSchool from "./AllQuestionsAnalysisAllSchool.vue";
export default {
  data() {
    return {};
  },
  components: {
    SingleSchool,
    AllSchool,
  },
  props: {
    asuulga: {
      type: Object,
      required: true,
    },
    // numberOfQuestions: {
    //   type: Number,
    //   required: true,
    // },
  },
  computed: {
    isSingleSchool() {
      if (this.$store.state.userData.school&&this.$store.state.userData.school.isSingleSchool) {
        return this.$store.state.userData.school.isSingleSchool;
      } else {
        return null;
      }
    },
  },
};
</script>
