var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.results)?_c('section',{staticClass:"mt-16 analiz"},[_c('v-card',{staticClass:"mx-auto pa-10",attrs:{"max-width":"50%"}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"space-between"}},[_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.asuulga.name)+", ("+_vm._s(_vm.asuulga.finalNumberOfParticipants)+" оролцогчид) ")]),(_vm.numberOfQuestions)?_c('p',[_vm._v(" Нийт асуултын тоо: "+_vm._s(_vm.numberOfQuestions)+" ")]):_vm._e()]),_c('v-btn',{staticClass:"bg-gradient-success white--text",on:{"click":function($event){return _vm._downloadExcel()}}},[_vm._v(" Download Excel ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.results,"items-per-page":-1,"sort-by":"correctPercent"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_c('td',[_vm._v(_vm._s(props.item.questionIndex))]),_c('td',{style:('A' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'A' ? 'Зөв.x' : '')}}),_c('td',{style:('B' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'B' ? 'Зөв.x' : '')}}),_c('td',{style:('C' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'C' ? 'Зөв.x' : '')}}),_c('td',{style:('D' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'D' ? 'Зөв.x' : '')}}),_c('td',{style:('E' == props.item.correctAnswer
                  ? 'background-color: #98ec2d !important;'
                  : ''),domProps:{"textContent":_vm._s(props.item.correctAnswer == 'E' ? 'Зөв.x' : '')}}),_c('td',[_vm._v(_vm._s(props.item.correctPercent)+"%")])])]}}],null,false,1836234842)}),_c('v-dialog',{model:{value:(_vm.closeAsuulgaSlide),callback:function ($$v) {_vm.closeAsuulgaSlide=$$v},expression:"closeAsuulgaSlide"}},[_c('v-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm._getSlideLink())}}),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"bg-gradient-danger white--text mb-3 mr-3",on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v("Хаах")])],1)])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }